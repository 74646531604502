import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import ProjectSec from '../../component/Section/Project'
import Head from '../../component/Section/Head'
import Choose from '../../component/Section/Choose'
import Price from '../../component/Section/Price'
import Faq from '../../component/Section/Faq'

const Project = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Our Projects"} page={"Our Projects"} />
                </div>

                <ProjectSec />

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h4 className='semi-bold mb-0 font__size--24 text__24-1024 color__pink text-capitalize'>Trusted by</h4>
                        </div>

                        <div className="wrapper__brand-list">
                            <img src="./../images/Company logo.png" alt="" />
                            <img src="./../images/Company logo-1.png" alt="" />
                            <img src="./../images/Company logo-2.png" alt="" />
                            <img src="./../images/Company logo-3.png" alt="" />
                            <img src="./../images/Company logo-4.png" alt="" />
                        </div>
                    </div>
                </section>

                <Faq />

                <ContactUs />

                <Footer />
            </div >
        </Fragment >
    )
}

export default Project