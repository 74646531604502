import React, { Fragment, useState } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import Head from '../../component/Section/Head'
import { FileUploader } from "react-drag-drop-files";

const Team = () => {
    const user = [
        {
            img: "./../images/tm (6).png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/tm (5).png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/tm (4).png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/tm (3).png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/tm (2).png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/tm (1).png",
            name: "Samuel",
            job: "Mobile Developer"
        },
    ]


    const fileTypes = ["JPG", "PNG", "DOC", "DOCX", "PDF"];

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Our Team"} page={"Our Team"} />
                </div>

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="row">
                                    {
                                        user.map((obj, i) => {
                                            return <div className="col-md-6 col-lg-4 mb-5">
                                                <div className={"wrapper__team-user position-relative " + ((i + 1) % 2 == 0 ? "rg" : "")}>
                                                    <img src={obj.img} className='bg' alt="" />
                                                    <img src="./../images/Group.png" className='cursor' alt="" />
                                                    <div className="desc text-center">
                                                        <img src="./../images/dasdsa.png" className='arrow' alt="" />
                                                        <div className="box">
                                                            <h5 className='medium font__size--14 text__14-1024 mb-0'>{obj.name}</h5>
                                                            <p className='mb-0 normal font__size--14 text__14-1024 color__gray-1'>{obj.job}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="mb-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Let’s Join us</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Available Position</h2>
                        </div>

                        <div className="row">
                            <div className="col-sm-6 mb-4 mb-xl-0 col-xl-3">
                                <div className="wrapper__card-jobs left">
                                    <img src="./../images/Graph.png" alt="" />
                                    <div className="ml-3">
                                        <h3 className='semi-bold font__size--20'>UI Designer</h3>
                                        <p className='mb-0 normal font__size--14 color__gray-1'>Remote / Full Time</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 mb-xl-0 col-xl-3">
                                <div className="wrapper__card-jobs right">
                                    <img src="./../images/Work.png" alt="" />
                                    <div className="ml-3">
                                        <h3 className='semi-bold font__size--20'>Project Manager</h3>
                                        <p className='mb-0 normal font__size--14 color__gray-1'>Remote / Full Time</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 mb-xl-0 col-xl-3">
                                <div className="wrapper__card-jobs left">
                                    <img src="./../images/Activity.png" alt="" />
                                    <div className="ml-3">
                                        <h3 className='semi-bold font__size--20'>Backend Dev</h3>
                                        <p className='mb-0 normal font__size--14 color__gray-1'>Remote / Full Time</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 mb-4 mb-xl-0 col-xl-3">
                                <div className="wrapper__card-jobs right">
                                    <img src="./../images/Activity.png" alt="" />
                                    <div className="ml-3">
                                        <h3 className='semi-bold font__size--20'>Frontend Dev</h3>
                                        <p className='mb-0 normal font__size--14 color__gray-1'>Remote / Full Time</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="wrapper__contact-wrap">
                            <div className="row">
                                <div className="col-md-6 mb-5 my-md-auto">
                                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Join us!</p>
                                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>Join our team!</h2>
                                    <p className='normal font__size--18 text__18-1024 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>

                                    <ul className="list__contact-list">
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="icon">
                                                    <img src="./../images/pin (1).png" alt="" />
                                                </div>
                                                <div className='ml-3 normal font__size--14 text__14-1024 color__gray-1'>2715 Ash Dr. San Jose, South Dakota 83475</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="icon">
                                                    <img src="./../images/call.png" alt="" />
                                                </div>
                                                <div className='ml-3 normal font__size--14 text__14-1024 color__gray-1'>(252) 555-0126</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="icon">
                                                    <img src="./../images/email.png" alt="" />
                                                </div>
                                                <div className='ml-3 normal font__size--14 text__14-1024 color__gray-1'>example@gmail.com</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-lg-5 offset-lg-1 my-auto">
                                    <div className="form-group">
                                        <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Name" />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Phone" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <select name="" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" id="">
                                            <option value="" selected disabled hidden>Position applied for</option>
                                            <option value="">Frontend Dev</option>
                                            <option value="">Backend Dev</option>
                                            <option value="">Project Manager</option>
                                            <option value="">UI Designer</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" className="form-control wrapper__field-input light font__size--14 text__14-1024 color__gray-1" placeholder="Portfolio link" />
                                    </div>


                                    <div className="wrapper__drop-wrap position-relative mb-3">
                                        <div className="center text-center color__gray-1">

                                            <img src={"./../images/Vector.png"} className='mb-3 mx-auto image__center' alt="" />
                                            <h5 className='medium font__size--14 text__14-1024 mb-0'>{file ? file?.name : "Drag and drop your file here"}</h5>
                                            {file ? "" : <p className='normal font__size--14 text__14-1024 color__gray-2 mb-0'>Or <span className='color__blue'>browse files</span></p>}

                                        </div>
                                        <div className="position-relative z-2">
                                            <FileUploader handleChange={handleChange} multiple={false} label={""} hoverTitle={""} classes="wrapper__drop-item" name="file" types={fileTypes} />
                                        </div>
                                    </div>


                                    <button className="semi-bold font__size--14 text__14-1024 btn btn__blue color__white shadow text-capitalize">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default Team