import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Head from '../../component/Section/Head'
import FaqSec from '../../component/Section/Faq'
import ContactForm from '../../component/Section/ContactForm'
import { useState } from 'react'

const Faq = () => {
    const data = [
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
        {
            title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
        },
    ]

    const [accordion, setAccordion] = useState(1)

    const onClickAccordion = (i) => {
        if (i == accordion) {
            setAccordion(0)
        } else {
            setAccordion(i)
        }
    }

    const [category, setCategory] = useState(["All", "Service", 'Contact', 'Payment'])
    const [selectCat, setSelectCat] = useState("All")
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"FAQ"} page={"FAQ"} />
                </div>

                <section>
                    <div className="container">
                        <div className="text-center mb-4">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Common Questions</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>Frequently Asked Questions</h2>
                        </div>

                        <div className="mb-5">
                            <div className="wrapper__tab-category">
                                {
                                    category.map((obj) => {
                                        return <div className={"items medium font__size--16 pointer " + (selectCat == obj ? "active" : '')} onClick={() => setSelectCat(obj)}>
                                            {obj}
                                        </div>
                                    })
                                }

                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8">
                                <div className="wrapper__accordion">
                                    {
                                        data.map((obj, i) => {
                                            return <div className={"items " + (accordion == (i + 1) ? "active" : "")}>
                                                <div className="head pointer" onClick={() => onClickAccordion(i + 1)}>
                                                    <div className="d-flex align-items-center">
                                                        <img src="./../images/cheveron-down.png" className='arrow flex-shrink-0 object-fit-content' alt="" />
                                                        <div className='semi-bold font__size--18 text__18-1024 ml-3'>{obj.title}</div>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>{obj.desc}</p>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactForm />

                <Footer />
            </div >
        </Fragment >
    )
}

export default Faq