import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import About from "./homepage/About";
import Service from "./homepage/Service";
import DetailService from "./homepage/DetailService";
import Testimonial from "./homepage/Testimonial";
import Pricing from "./homepage/Pricing";
import Contact from "./homepage/Contact";
import Team from "./homepage/Team";
import Project from "./homepage/Project";
import Faq from "./homepage/Faq";
import DetailProject from "./homepage/DetailProject";
import PageNotFound from "./homepage/PageNotFound";
import Terms from "./homepage/Terms";
import Privacy from "./homepage/Privacy";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/service">
            <Service />
          </Route>
          <Route exact path="/service/detail">
            <DetailService />
          </Route>
          <Route exact path="/testimonial">
            <Testimonial />
          </Route>
          <Route exact path="/pricing">
            <Pricing />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/project">
            <Project />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/project/detail">
            <DetailProject />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};

export default Index;
