import React from 'react'

const Quote = () => {
    return (
        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-5 col-lg-4 mb-4 mb-md-0">
                                <img src="./../images/Group 35266.png" className='w-100' alt="" />
                            </div>
                            <div className="col-md-7 col-lg-8 pl-lg-5 my-auto">
                                <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-0'>“Maximize Your Reach with Marketing - Unlock Your Business's Growth Potential!"</h3>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 my-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>

                                <h5 className='normal font__size--24 text__24-1024 color__blue mb-0'>Albert William</h5>
                                <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Project Manager / CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Quote