import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <section className='pb-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div className="d-flex flex-wrap h-100">
                            <div className='w-100'>
                                <img src="./../images/Logo.png" className='mb-3' alt="" />
                                <p className='normal font__size--14 text__14-1024 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididum ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                            </div>
                            <div className="align-self-end d-none d-lg-block">
                                <p className='mb-0 normal font__size--14 text__14-1024'>© Copyright 2022, All Rights Reserved by Nagency</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-0 col-xl-3 offset-xl-1">
                        <div className="row">
                            <div className="col-6">
                                <h5 className='semi-bold font__size--16 text__16-1024 mb-3'>Quick links</h5>
                                <ul className="list__menu-footer font__size--14 text__14-1024 normal">
                                    <li><NavLink to="/" className='color__black opacity__7 hover'>Home</NavLink></li>
                                    <li><NavLink to="/about" className='color__black opacity__7 hover'>About</NavLink></li>
                                    <li><NavLink to="/service" className='color__black opacity__7 hover'>Service</NavLink></li>
                                    <li><NavLink to="/testimonial" className='color__black opacity__7 hover'>Testimonial</NavLink></li>
                                    <li><NavLink to="/" className='color__black opacity__7 hover'>Pages</NavLink></li>
                                    <li><NavLink to="/pricing" className='color__black opacity__7 hover'>Pricing</NavLink></li>
                                </ul>
                            </div>
                            <div className="col-6">
                                <h5 className='semi-bold font__size--16 text__16-1024 mb-3'>Help</h5>
                                <ul className="list__menu-footer font__size--14 text__14-1024 normal">
                                    <li><NavLink to="/privacy" className='color__black opacity__7 hover'>Privacy Policy</NavLink></li>
                                    <li><NavLink to="/terms" className='color__black opacity__7 hover'>Terms & Conditions</NavLink></li>
                                    <li><NavLink to="/contact" className='color__black opacity__7 hover'>Contact us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex flex-wrap h-100 pb-4 pb-lg-0">
                            <div className='w-100'>
                                <h5 className='semi-bold font__size--16 text__16-1024 mb-3'>Subscribe to newsletter</h5>
                                <p className='normal font__size--16 text__16-1024 color__gray-1'>Lorem ipsum dolor sit amet, consec tetur adip iscing elit. Sit quis auctor.</p>

                                <div className="wrapper__subscribe d-flex align-items-center mb-4">
                                    <input type="text" placeholder='Enter email address' className='normal font__size--14 text__14-1024 color__gray-1' />
                                    <button className='btn btn__pink color__white shadow font__size--12 text__12-1024 bold'>Subscribe</button>
                                </div>
                            </div>
                            <div className="align-self-lg-end">
                                <div className="wrapper__sosmed-footer d-flex align-items-center">
                                    <p className='mb-0 normal font__size--16 text__16-1024 color__gray-1'>Follow us on:</p>
                                    <div className="link d-flex align-items-center">
                                        <a href="$!"><img src="./../images/1.png" alt="" /></a>
                                        <a href="$!"><img src="./../images/2.png" alt="" /></a>
                                        <a href="$!"><img src="./../images/3.png" alt="" /></a>
                                        <a href="$!"><img src="./../images/4.png" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-lg-none">
                    <p className='normal mb-0 font__size--14 text__14-1024 text-center'>© Copyright 2022, All Rights Reserved by Nagency</p>
                </div>
            </div>
        </section>
    )
}

export default Footer