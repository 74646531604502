import React from 'react'
import { NavLink } from 'react-router-dom'

const Project = () => {
    const project = [
        {
            img: "./../images/ad (2).png",
            link: "#!"
        },
        {
            img: "./../images/ad (4).png",
            link: "#!"
        },
        {
            img: "./../images/ad (6).png",
            link: "#!"
        },
        {
            img: "./../images/ad (3).png",
            link: "#!"
        },
        {
            img: "./../images/ad (5).png",
            link: "#!"
        },
        {
            img: "./../images/ad (1).png",
            link: "#!"
        },
    ]
    return (
        <section>
            <div className="container">
                <div className="text-center mb-5">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Projects</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Latest Project We Have Done</h2>
                </div>

                <div className="row">
                    {
                        project.map((obj, i) => {
                            return <div className="col-md-6 col-lg-4 mb-5">
                                <NavLink to="/project/detail" className="d-inline-block w-100">
                                    <img src={obj.img} alt="" className={'images__project ' + ((i + 1) % 2 == 0 ? "lf" : "")} />
                                </NavLink>
                            </div>
                        })
                    }

                </div>
            </div>
        </section>
    )
}

export default Project