import React, { Fragment } from 'react'
import { useRef } from 'react';
import Slider from "react-slick";

const SliderClient = () => {
    const settings = {
        dot: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };
    return (
        <Fragment>
            <div className="wrapper__offset-slide">
                <Slider {...settings} className="wrapper__slider-client">
                    <div className="items">
                        <div className="wrapper__client-list">
                            <img src="./../images/sdfd (1).png" className='user' alt="" />
                            <div className='ml-md-4 desc'>
                                <h4 className='semi-bold font__size--32 text__32-1024 text__32-xs'>“Maximize Your Reach with Marketing - Unlock Your Business's Growth Potential!"</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <h5 className='normal font__size--16 text__16-1024 color__blue'>Albert Fox</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color_-gray-1'>CEO Dream Team</p>
                                    </div>
                                    <img src="./../images/sdfd (2).png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="items">
                        <div className="wrapper__client-list">
                            <img src="./../images/Image (5).png" className='user' alt="" />
                            <div className='ml-md-4 desc'>
                                <h4 className='semi-bold font__size--32 text__32-1024 text__32-xs'>“Maximize Your Reach with Marketing - Unlock Your Business's Growth Potential!"</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <h5 className='normal font__size--16 text__16-1024 color__blue'>Albert Fox</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color_-gray-1'>CEO Dream Team</p>
                                    </div>
                                    <img src="./../images/sdfd (2).png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="items">
                        <div className="wrapper__client-list">
                            <img src="./../images/sdfd (1).png" className='user' alt="" />
                            <div className='ml-md-4 desc'>
                                <h4 className='semi-bold font__size--32 text__32-1024 text__32-xs'>“Maximize Your Reach with Marketing - Unlock Your Business's Growth Potential!"</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <h5 className='normal font__size--16 text__16-1024 color__blue'>Albert Fox</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color_-gray-1'>CEO Dream Team</p>
                                    </div>
                                    <img src="./../images/sdfd (2).png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="items">
                        <div className="wrapper__client-list">
                            <img src="./../images/Image (5).png" className='user' alt="" />
                            <div className='ml-md-4 desc'>
                                <h4 className='semi-bold font__size--32 text__32-1024 text__32-xs'>“Maximize Your Reach with Marketing - Unlock Your Business's Growth Potential!"</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <h5 className='normal font__size--16 text__16-1024 color__blue'>Albert Fox</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color_-gray-1'>CEO Dream Team</p>
                                    </div>
                                    <img src="./../images/sdfd (2).png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </Fragment>
    )
}

export default SliderClient