import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import Faq from '../../component/Section/Faq'
import Head from '../../component/Section/Head'
import TestimonialSec from '../../component/Section/Testimonial'
import SliderClient from '../../component/Slider/SliderClient'

const Testimonial = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Testimonial"} page={"Testimonial"} />
                </div>

                <TestimonialSec />

                <section>
                    <div className="container">
                        <div className="mb-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Clients</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>There are also many large companies <br className='d-none d-md-block' /> that collaborate with us</h2>
                        </div>
                        <SliderClient />
                    </div>
                </section>

                <Faq />

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default Testimonial