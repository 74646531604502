import React from 'react'
import { NavLink } from 'react-router-dom'

const Service = (props) => {
    const services = [
        {
            img: "./../images/Chart.png",
            title: "Consultant Plan",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            link: "#!"
        },
        {
            img: "./../images/Graph.png",
            title: "Design Thingking",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            link: "#!"
        },
        {
            img: "./../images/Filter 2.png",
            title: "SEO Marketing",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            link: "#!"
        },
        {
            img: "./../images/Work.png",
            title: "Business Advisor",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            link: "#!"
        },
        {
            img: "./../images/Heart.png",
            title: "Brand Personal",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            link: "#!"
        },
        {
            img: "./../images/Activity.png",
            title: "Development",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
            link: "#!"
        },
    ]
    return (
        <section>
            <div className="container">
                <div className="text-center mb-5">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Service</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>What Can We Bring for You</h2>
                </div>

                <div className="row">
                    {
                        props.type == "left" ? services.map((obj) => {
                            return <div className="col-md-6 col-lg-4 mb-4">
                                <div className="wrapper__card-service position-relative">
                                    <img src="./../images/mouse 2.png" className='cursor' alt="" />
                                    <div className="position-relative z-2 left">
                                        <img src={obj.img} className='mb-5' alt="" />
                                        <div className='ml-3'>
                                            <h4 className='semi-bold font__size--24 text__24-1024'>{obj.title}</h4>
                                            <p className='normal font__size--18 text__18-1024 color__gray-1'>{obj.desc}</p>
                                            <div className="d-inline-block">
                                                <NavLink to="/service/detail" className='color__blue'>
                                                    <div className="d-flex align-items-center normal font__size--18 text__18-1024">
                                                        <span className='mr-2'>Read More</span> <img src="./../images/right-arrow (1) 1.png" alt="" />
                                                    </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }) : services.map((obj) => {
                            return <div className="col-md-6 col-lg-4 mb-4">
                                <div className="wrapper__card-service position-relative">
                                    <img src="./../images/mouse 2.png" className='cursor' alt="" />
                                    <div className="position-relative z-2">
                                        <img src={obj.img} className='mb-5' alt="" />
                                        <h4 className='semi-bold font__size--24 text__24-1024'>{obj.title}</h4>
                                        <p className='normal font__size--18 text__18-1024 color__gray-1'>{obj.desc}</p>
                                        <div className="d-inline-block">
                                            <NavLink to="/service/detail" className='color__blue'>
                                                <div className="d-flex align-items-center normal font__size--18 text__18-1024">
                                                    <span className='mr-2'>Read More</span> <img src="./../images/right-arrow (1) 1.png" alt="" />
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })

                    }

                </div>
            </div>
        </section>
    )
}

export default Service