import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import Head from '../../component/Section/Head'
import Faq from '../../component/Section/Faq'

const DetailService = () => {
    const menu = [
        {
            title: "Consultant Planning",
            link: "#!"
        },
        {
            title: "Design Thingking",
            link: "#!"
        },
        {
            title: "SEO Marketing",
            link: "#!"
        },
        {
            title: "Business Advisor",
            link: "#!"
        },
        {
            title: "Brand Personal",
            link: "#!"
        },
        {
            title: "Development",
            link: "#!"
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Detail Service"} page={"Detail Service"} />
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 mb-5 mb-md-0 col-xl-3">
                                <h2 className='bold font__size--24 text__24-1024 mb-4'>Service</h2>

                                <div className="wrapper__list-service">
                                    {
                                        menu.map((obj, i) => {
                                            return <a href={obj.link} className={"items d-inline-block w-100 color__gray-1 pointer " + (i == 0 ? "active" : "")}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className='medium font__size--16 text__16-1024'>{obj.title}</span>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.90638 15.5676L5.90618 15.5674L5.90131 15.5732C5.65195 15.868 5.68155 16.2885 5.98291 16.553C6.30779 16.838 6.82979 16.838 7.15467 16.553L14.0174 10.5314L14.0176 10.5316L14.0227 10.5264L14.0939 10.4541L14.0941 10.4543L14.099 10.4485C14.3484 10.1536 14.3188 9.73314 14.0174 9.46872L7.15467 3.44721L7.15485 3.44702L7.1491 3.44267L7.06663 3.3802L7.06679 3.38L7.06081 3.37611C6.73689 3.16523 6.27898 3.18744 5.98291 3.44721L5.98273 3.44701L5.97758 3.45224L5.90638 3.5246L5.90618 3.52441L5.90131 3.53017C5.65195 3.82504 5.68155 4.24552 5.9829 4.50994L12.2397 10.0001L5.9829 15.4902L5.98273 15.49L5.97758 15.4953L5.90638 15.5676Z" fill="#657791" stroke="#657791" stroke-width="0.2" />
                                                    </svg>

                                                </div>
                                            </a>
                                        })
                                    }

                                </div>
                            </div>
                            <div className="col-md-8 offset-xl-1">
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-3'>Detail Service</p>
                                <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>Consultant Planning</h2>

                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>

                                <div className="wrapper__play-video detailService position-relative mb-4">
                                    <div className="play pointer">
                                        <img src="./../images/play.png" alt="" />
                                    </div>
                                    <img src="./../images/Rectangle 22146.png" className='bg' alt="" />
                                </div>

                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>What We Do</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>What Our Team Do</h2>
                        </div>

                        <div className="row wrapper__choose-line-wrap">
                            <div className="col-md-4">
                                <div className="wrapper__card-choose">
                                    <div className="icon mb-5">
                                        <img src="./../images/to-do-list.png" alt="" />
                                    </div>
                                    <h4 className='semi-bold font__size--24 text__24-1024'>Planning</h4>
                                    <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </div>
                            </div>
                            <div className="col-md-4 position-relative line-dual">
                                <div className="wrapper__card-choose">
                                    <div className="icon mb-5">
                                        <img src="./../images/correction.png" alt="" />
                                    </div>
                                    <h4 className='semi-bold font__size--24 text__24-1024'>Validation</h4>
                                    <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="wrapper__card-choose">
                                    <div className="icon mb-5">
                                        <img src="./../images/decision-making.png" alt="" />
                                    </div>
                                    <h4 className='semi-bold font__size--24 text__24-1024'>Decition</h4>
                                    <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Faq />

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default DetailService