import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import Head from '../../component/Section/Head'
import Project from '../../component/Section/Project'

const DetailProject = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Detail Projects"} page={"Detail Projects"} />
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 mb-5 mb-md-0 col-xl-3">
                                <div className="wrapper__side-link">
                                    <div className="items">
                                        <h5 className='semi-bold font__size--16 text__16-1024 mb-1'>Client</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color__gray-1'>Schoolmy Agency</p>
                                    </div>
                                    <div className="items">
                                        <h5 className='semi-bold font__size--16 text__16-1024 mb-1'>Long work</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color__gray-1'>2 Months</p>
                                    </div>
                                    <div className="items">
                                        <h5 className='semi-bold font__size--16 text__16-1024 mb-1'>Category</h5>
                                        <p className='mb-0 normal font__size--14 text__14-1024 color__gray-1'>Web Development</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 offset-xl-1">
                                <div className="d-flex flex-wrap flex-xl-nowrap align-items-end justify-content-between mb-5">
                                    <div className='w-100 mb-3 mb-xl-0'>
                                        <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Project Name</p>
                                        <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-0'>Educational Website Dev</h2>
                                    </div>
                                    <a href='#!' className="d-flex align-items-center color__blue d-inline-block mb-2 flex-shrink-0">
                                        <img src="./../images/web.png" alt="" />
                                        <span className='ml-2 normal font__size--24 text__24-1024'>Visit Web</span>
                                    </a>
                                </div>

                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>

                                <img src="./../images/ssdfsdf.png" className='images__detail-project mb-5' alt="" />

                                <h4 className='semi-bold font__size--32 text__32-1024 mb-4'>Challenge This Project</h4>

                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>

                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Project />

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default DetailProject