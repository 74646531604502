import React, { useRef } from 'react'
import Slider from "react-slick";

const SliderTestimonial = (props) => {

    const user = [
        {
            img: "./../images/ff (7).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (6).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (5).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (4).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (3).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (2).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (1).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
        {
            img: "./../images/ff (4).png",
            desc: "Lorem ipsum dolor sit amet, consect etur adipiscing elit - @jennifer.a",
        },
    ]

    const settings = {
        centerMode: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: true,
        adaptiveHeight: true,
        pauseOnHover: false,
        autoplaySpeed: 0,
        autoplay: true,
        speed: 5000,
        cssEase: "linear",
        rtl: props.rtl,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    adaptiveHeight: false,
                }
            }
        ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };
    return (
        <Slider {...settings} className="wrapper__slider-testimonial">
            {
                user.map((obj) => {
                    return <div className="items">
                        <div className="user__about">
                            <div className="d-flex align-items-center">
                                <img src={obj.img} className='img flex-shrink-0' alt="" />
                                <div className='normal font__size--16 text__16-1024 color__gray-1 ml-3 clamp__2'>{obj.desc}</div>
                            </div>
                        </div>
                    </div>
                })
            }

        </Slider>
    )
}

export default SliderTestimonial