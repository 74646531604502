import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import Head from '../../component/Section/Head'
import Price from '../../component/Section/Price'
import VideoWorks from '../../component/Section/VideoWorks'
import ServiceSec from '../../component/Section/Service'

const Service = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Service"} page={"Service"} />
                </div>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Service</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>What Can We Bring for You</h2>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="row mb-5">
                                    <div className="col-md-6 mb-4 my-md-auto">
                                        <img src="./../images/sa (2).png" className='images__preview-sevice left' alt="" />
                                    </div>
                                    <div className="col-md-6 pl-md-5 my-auto">
                                        <img src="./../images/Chart.png" className='mb-3' alt="" />
                                        <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Consultant Plan</h3>
                                        <p className='normal font__size--14 text__14-1024 color__gray-1 lh-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-6 pr-md-5 my-auto order-12 order-md-1">
                                        <img src="./../images/Graph.png" className='mb-3' alt="" />
                                        <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Design Thingking</h3>
                                        <p className='normal font__size--14 text__14-1024 color__gray-1 lh-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                                    </div>
                                    <div className="col-md-6 my-md-auto order-md-12 order-1 mb-4">
                                        <img src="./../images/sa (3).png" className='images__preview-sevice right' alt="" />
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-6 mb-4 my-md-auto">
                                        <img src="./../images/sa (4).png" className='images__preview-sevice left' alt="" />
                                    </div>
                                    <div className="col-md-6 pl-md-5 my-auto">
                                        <img src="./../images/Filter 2.png" className='mb-3' alt="" />
                                        <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>SEO Marketing</h3>
                                        <p className='normal font__size--14 text__14-1024 color__gray-1 lh-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-6 pr-md-5 my-auto order-12 order-md-1">
                                        <img src="./../images/Work.png" className='mb-3' alt="" />
                                        <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Business Advisor</h3>
                                        <p className='normal font__size--14 text__14-1024 color__gray-1 lh-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                                    </div>
                                    <div className="col-md-6 my-md-auto order-md-12 order-1 mb-4">
                                        <img src="./../images/sa (5).png" className='images__preview-sevice right' alt="" />
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-6 mb-4 my-md-auto">
                                        <img src="./../images/sa (6).png" className='images__preview-sevice left' alt="" />
                                    </div>
                                    <div className="col-md-6 pl-md-5 my-auto">
                                        <img src="./../images/Heart.png" className='mb-3' alt="" />
                                        <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Brand Personal</h3>
                                        <p className='normal font__size--14 text__14-1024 color__gray-1 lh-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-md-6 pr-md-5 my-auto order-12 order-md-1">
                                        <img src="./../images/Activity.png" className='mb-3' alt="" />
                                        <h3 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Design Thingking</h3>
                                        <p className='normal font__size--14 text__14-1024 color__gray-1 lh-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                                    </div>
                                    <div className="col-md-6 my-md-auto order-md-12 order-1 mb-4">
                                        <img src="./../images/sa (1).png" className='images__preview-sevice right' alt="" />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>

                <VideoWorks />

                <Price />

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default Service