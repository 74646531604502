import React, { Fragment, useState } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'

const Privacy = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <section className="position-relative z-2">
                        <div className="container text-center">
                            <h2 className='semi-bold font__size--60 text__60-1024 text__60-sm'>Privacy Policy</h2>
                            <p className='mb-0 normal font__size--18 text__18-1024'>Last Update Dec 18, 2022</p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-5'>Dui sed elementum, ornare etiam mauris purus eu quam. Commodo posuere duis pellentesque vel etiam turpis sed. Fermentum eleifend ut neque, fringilla nisl convallis. Nibh tincidunt diam aliquam, et. Adipiscing consequat amet, at mattis magna amet, urna duis.
                                </p>
                                <div className="mb-5">
                                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Licensing Policy</h2>
                                    <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2'>Lobortis in cras orci, ultricies egestas rhoncus integer. Orci erat adipiscing massa sed non. Vitae quisque accumsan tellus convallis enim, at lacus, faucibus vel. Viverra ridiculus etiam pulvinar convallis sed. Metus enim, in iaculis eu vitae laoreet eget. Lacinia mauris vulputate tortor, quis auctor tortor. Lacus, nullam enim elementum.</p>
                                </div>

                                <div className="mb-5">
                                    <h4 className='semi-bold font__size--20 text__20-1024 mb-3'>Pellentesque dictumst amet cras eget. Nunc elementum, fames non venenatis:</h4>

                                    <ul className='list__normal normal font__size--18 text__18-1024 color__gray-1'>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Dui sed elementum, ornare etiam mauris purus eu quam. Commodo posuere duis pellentesque vel etiam.
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Fames senectus curabitur arcu, viverra interdum. Cras sit lectus enim sed arcu cras urna magna.
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Adipiscing enim phasellus non eros, viverra tempus sit adipiscing. Sit enim at velit arcu risus.
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    At eget fringilla gravida in turpis risus vitae diam. Diam ligula aliquam facilisis molestie pretium arcu consectetur .
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="mb-5">
                                    <h4 className='semi-bold font__size--20 text__20-1024 mb-3'>Tempor, aliquam egestas faucibus euismod orci, mattis faucibus:</h4>

                                    <ul className='list__normal normal font__size--18 text__18-1024 color__gray-1'>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Dui sed elementum, ornare etiam mauris purus eu quam. Commodo posuere duis pellentesque vel etiam.
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Fames senectus curabitur arcu, viverra interdum. Cras sit lectus enim sed arcu cras urna magna.
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                                <div className="mb-5">
                                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Additional Policy</h2>
                                    <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2'>Lobortis in cras orci, ultricies egestas rhoncus integer. Orci erat adipiscing massa sed non. Vitae quisque accumsan tellus convallis enim, at lacus, faucibus vel. Viverra ridiculus etiam pulvinar convallis sed. Metus enim, in iaculis eu vitae laoreet eget. Lacinia mauris vulputate tortor, quis auctor tortor. Lacus, nullam enim elementum.</p>
                                </div>

                                <div className="mb-5">
                                    <h4 className='semi-bold font__size--20 text__20-1024 mb-3'>Platea diam et viverra morbi consequat interdum sed. Massa fringilla natoque faucibus nulla </h4>

                                    <ul className='list__normal normal font__size--18 text__18-1024 color__gray-1'>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Dui sed elementum, ornare etiam mauris purus eu quam. Commodo posuere duis pellentesque vel etiam.
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-start">
                                                <img src="./../images/Frame (1).png" className='object-fit-content mt-1' alt="" />
                                                <div className="ml-2">
                                                    Fames senectus curabitur arcu, viverra interdum. Cras sit lectus enim sed arcu cras urna magna.
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <h4 className='semi-bold font__size--20 text__20-1024 mb-3'>Metus, sodales sit risus, orci ultricies gravida ut. Quisque quisque lorem euismod vulputat.</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 lh-2'>Lobortis in cras orci, ultricies egestas rhoncus integer. Orci erat adipiscing massa sed non. Vitae quisque accumsan tellus convallis enim, at lacus, faucibus vel. Viverra ridiculus etiam pulvinar convallis sed. Metus enim, in iaculis eu vitae laoreet eget. Lacinia mauris vulputate tortor, quis auctor tortor. Lacus, nullam enim elementum.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </div >
        </Fragment >
    )
}

export default Privacy