import React from 'react'
import SliderTestimonial from '../Slider/SliderTestimonial'

const Testimonial = () => {
    return (
        <section>
            <div className="container-fluid">
                <div className="mb-5 text-center">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Testimonials</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Our happy clients say about us</h2>
                </div>


                <div className="mb__min-3">
                    <SliderTestimonial />
                </div>
                <SliderTestimonial rtl={true} />
            </div>
        </section>
    )
}

export default Testimonial