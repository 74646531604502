import React from 'react'

const VideoWorks = () => {
    return (
        <section>
            <div className="container">
                <div className="text-center mb-5">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Works</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>This is How We Works</h2>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <div className="wrapper__play-video position-relative">
                            <div className="play pointer">
                                <img src="./../images/play.png" alt="" />
                            </div>
                            <img src="./../images/Rectangle 22145.png" className='bg' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoWorks