import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import Choose from '../../component/Section/Choose'
import ContactUs from '../../component/Section/ContactUs'
import Head from '../../component/Section/Head'
import PersonalTeam from '../../component/Section/PersonalTeam'
import Quote from '../../component/Section/Quote'
import Testimonial from '../../component/Section/Testimonial'
import VideoWorks from '../../component/Section/VideoWorks'

const About = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"About us"} page={"About us"} />
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 my-auto">
                                <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>About us</p>
                                <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>We Have 15 Years Of Experience On Creative Digital Agency</h2>
                                <p className='normal font__size--18 text__18-1024 lh-2 color__gray-1 mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>

                                <div className="d-flex align-itesm-center my-4">
                                    <div>
                                        <h4 className='semi-bold font__size--24 text__24-1024 color__blue'>1254+</h4>
                                        <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Happy Clients</p>
                                    </div>
                                    <div className='ml-4'>
                                        <h4 className='semi-bold font__size--24 text__24-1024 color__blue'>3245+</h4>
                                        <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>Project Complated</p>
                                    </div>
                                </div>

                                <NavLink to="/service" className="semi-bold font__size--14 text__14-1024 btn btn__blue color__white shadow ml-auto text-capitalize">View Service</NavLink>
                            </div>
                            <div className="col-md-6 d-none d-md-block my-auto text-center">
                                <img src="./../images/Group 35252.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <Quote />

                <section className='pb-0'>
                    <div className="container">
                        <div className="wrapper__number-project text-center mb-5">
                            <div className="items">
                                <h4 className='semi-bold font__size--48  text__48-1024 text__48-md text__48-sm'>320+</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-0'>Complate Projects</p>
                            </div>
                            <div className="items">
                                <h4 className='semi-bold font__size--48  text__48-1024 text__48-md text__48-sm'>25+</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-0'>Cooperation company</p>
                            </div>
                            <div className="items">
                                <h4 className='semi-bold font__size--48  text__48-1024 text__48-md text__48-sm'>120+</h4>
                                <p className='normal font__size--18 text__18-1024 color__gray-1 mb-0'>Happy Clients</p>
                            </div>
                        </div>

                        <div className="text-center pt-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Our Visions</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Provide The Creative Solutions</h2>
                            <p className='normal font__size--18 text__18-1024 lh-2 color__gray-1 mb-3 mb-lg-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore <br className='d-none d-lg-block' /> magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                        </div>
                    </div>
                </section>

                <PersonalTeam />

                <VideoWorks />

                <Testimonial />

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default About