import React from 'react'
import { NavLink } from 'react-router-dom'

const Price = () => {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-xl-6 mb-5 mb-lg-0">
                        <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Pricing</p>
                        <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Benefits That You Can Feel Immediately</h2>
                        <p className='normal font__size--18 text__18-1024 lh-2 color__gray-1 mb-3 mb-lg-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Utenimad minim veniam, quis nostrud exercitation dolore magna</p>
                        <NavLink to="/pricing" className="semi-bold font__size--14 text__14-1024 btn btn__blue color__white shadow ml-auto text-capitalize">See All Pricing</NavLink>
                    </div>
                    <div className="col-lg-8 col-xl-6">
                        <div className="row">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <div className="wrapper__price-wrap text-center">
                                    <h5 className='semi-bold font__size--20 text__20-1024 color__white mb-0'>Business</h5>
                                    <p className='normal font__size--16 text__16-1024 color__white mb mb-4'>Customer Reach & Budget</p>

                                    <h2 className='bold font__size--48 color__white mb-4'>$49<sub className='normal font__size--16 text__16-1024'>/Month</sub></h2>

                                    <ul className='normal font__size--16 text__16-1024 color__white list__detail-price mb-4'>
                                        <li>1 Service</li>
                                        <li>Free Platform Access</li>
                                        <li>24/7 Customer Support</li>
                                    </ul>

                                    <a href="#!" className='semi-bold font__size--14 text__14-1024 btn btn__white shadow color__black'>Purchase Now</a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="wrapper__price-wrap white text-center">
                                    <h5 className='semi-bold font__size--20 text__20-1024 mb-0'>Corporate</h5>
                                    <p className='normal font__size--16 text__16-1024 mb mb-4 color__gray-1'>Customer Reach & Budget</p>

                                    <h2 className='bold font__size--48 mb-4'>$199<sub className='normal font__size--16 text__16-1024'>/Month</sub></h2>

                                    <ul className='normal font__size--16 text__16-1024 list__detail-price color__gray-1 mb-4'>
                                        <li>2 Service</li>
                                        <li>Free Platform Access</li>
                                        <li>24/7 Customer Support</li>
                                    </ul>

                                    <a href="#!" className='semi-bold font__size--14 text__14-1024 btn btn__blue shadow color__white'>Purchase Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Price