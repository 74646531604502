import React, { Fragment } from 'react'
import Footer from '../../component/Other/Footer'
import Navbar from '../../component/Other/Navbar'
import ContactUs from '../../component/Section/ContactUs'
import Faq from '../../component/Section/Faq'
import Head from '../../component/Section/Head'

const Pricing = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">

                <div className="position-relative bg__softblue">
                    <Navbar />
                    <Head title={"Pricing"} page={"Pricing"} />
                </div>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Pricing</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Benefits That You Can <br className='d-none d-sm-block' /> Feel Immediately</h2>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="row justify-content-center">
                                    <div className="mb-4 mb-lg-0 col-md-6 col-lg-4">
                                        <div className="wrapper__price-wrap white text-center">
                                            <h5 className='semi-bold font__size--20 text__20-1024 mb-0'>Individual</h5>
                                            <p className='normal font__size--16 text__16-1024 mb mb-4 color__gray-1'>Customer Reach & Budget</p>

                                            <h2 className='bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>$39<sub className='normal font__size--16 text__16-1024'>/Month</sub></h2>

                                            <ul className='normal font__size-16 text__16-1024 list__detail-price color__gray-1 mb-4'>
                                                <li>2 Service</li>
                                                <li>Free Platform Access</li>
                                                <li>24/7 Customer Support</li>
                                            </ul>

                                            <a href="#!" className='semi-bold font__size--14 text__14-1024 btn btn__blue shadow color__white'>Purchase Now</a>
                                        </div>
                                    </div>
                                    <div className="mb-4 mb-lg-0 col-md-6 col-lg-4">
                                        <div className="wrapper__price-wrap text-center">
                                            <h5 className='semi-bold font__size--20 text__20-1024 color__white mb-0'>Business</h5>
                                            <p className='normal font__size--16 text__16-1024 color__white mb mb-4'>Customer Reach & Budget</p>

                                            <h2 className='bold font__size--48 text__48-1024 text__48-md text__48-sm color__white mb-4'>$49<sub className='normal font__size--16 text__16-1024'>/Month</sub></h2>

                                            <ul className='normal font__size-16 text__16-1024 color__white list__detail-price mb-4'>
                                                <li>1 Service</li>
                                                <li>Free Platform Access</li>
                                                <li>24/7 Customer Support</li>
                                            </ul>

                                            <a href="#!" className='semi-bold font__size--14 text__14-1024 btn btn__white shadow color__black'>Purchase Now</a>
                                        </div>
                                    </div>
                                    <div className="mb-4 mb-lg-0 col-md-6 col-lg-4">
                                        <div className="wrapper__price-wrap white text-center">
                                            <h5 className='semi-bold font__size--20 text__20-1024 mb-0'>Corporate</h5>
                                            <p className='normal font__size--16 text__16-1024 mb mb-4 color__gray-1'>Customer Reach & Budget</p>

                                            <h2 className='bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>$199<sub className='normal font__size--16 text__16-1024'>/Month</sub></h2>

                                            <ul className='normal font__size-16 text__16-1024 list__detail-price color__gray-1 mb-4'>
                                                <li>2 Service</li>
                                                <li>Free Platform Access</li>
                                                <li>24/7 Customer Support</li>
                                            </ul>

                                            <a href="#!" className='semi-bold font__size--14 text__14-1024 btn btn__blue shadow color__white'>Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="text-center mb-5">
                            <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Compare</p>
                            <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Compare & get your plan</h2>
                        </div>

                        <div className="wrapper__offsite-wrap">
                            <div className="wrapper__table-compare-group">
                                <table class="table head noborder">
                                    <col class="w-f-first" />
                                    <thead>
                                        <tr className='text-center'>
                                            <th scope="col"></th>
                                            <th scope="col">
                                                <h5 className='bold font__size--18 text__18-1024 mb-3'>Individual</h5>
                                                <div className='bold font__size--18 text__18-1024 btn btn__blue color__white px-4'>Get for $39</div>
                                            </th>
                                            <th scope="col">
                                                <h5 className='bold font__size--18 text__18-1024 mb-3'>Business</h5>
                                                <div className='bold font__size--18 text__18-1024 btn btn__blue color__white px-4'>Get for $49</div>
                                            </th>
                                            <th scope="col">
                                                <h5 className='bold font__size--18 text__18-1024 mb-3'>Corporate</h5>
                                                <div className='bold font__size--18 text__18-1024 btn btn__blue color__white px-4'>Get for $199</div>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                                <table class="table head text-center">
                                    <col class="w-f-first" />
                                    <thead>
                                        <tr className='text-center first'>
                                            <th scope="col" className=' bold font__size--18 text__18-1024 text-left'>Service</th>
                                            <th scope="col">

                                            </th>
                                            <th scope="col">

                                            </th>
                                            <th scope="col">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='medium font__size--16 text__16-1024'>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left normal'>Service</th>
                                            <td>1 Service</td>
                                            <td>1 Service</td>
                                            <td>2 Service</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left normal'>Platform</th>
                                            <td>Free</td>
                                            <td>Free</td>
                                            <td>Free</td>
                                        </tr>
                                    </tbody>

                                    <thead>
                                        <tr className='text-center spacing-top'>
                                            <th scope="col" className='bold font__size--18 text__18-1024 text-left'>Website</th>
                                            <th scope="col">

                                            </th>
                                            <th scope="col">

                                            </th>
                                            <th scope="col">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='medium font__size--16 text__16-1024'>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Custom Domain</th>
                                            <td></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Cloud Hosting</th>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Export Codes</th>
                                            <td></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Live Preview</th>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                    </tbody>



                                    <thead>
                                        <tr className='text-center spacing-top'>
                                            <th scope="col" className='bold font__size--18 text__18-1024 text-left'>Email Marketing</th>
                                            <th scope="col">

                                            </th>
                                            <th scope="col">

                                            </th>
                                            <th scope="col">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='medium font__size--16 text__16-1024'>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Campaigns</th>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Drip Automation</th>
                                            <td></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Export Customers</th>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" cl
                                                className='text-left medium'>Popup Builder</th>
                                            <td></td>
                                            <td></td>
                                            <td><img src="./../images/check.png" alt="" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <Faq />

                <ContactUs />

                <Footer />

            </div >
        </Fragment >
    )
}

export default Pricing