import React from 'react'
import { useState } from 'react'

const Faq = () => {

  const data = [
    {
      title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
    },
    {
      title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
    },
    {
      title: "Lorem ipsum dolor sit amet, consect etur adipiscing elit",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat, tempor for the main condimentum commodo tincidunt sit dictumst. Eu placerat to a arcu at sem vitae eros, purus nonprofit organizations."
    },
  ]

  const [accordion, setAccordion] = useState(1)

  const onClickAccordion = (i) => {
    if (i == accordion) {
      setAccordion(0)
    } else {
      setAccordion(i)
    }
  }
  return (
    <section>
      <div className="position-relative">
        <img src="./../images/Image (4).png" className='images__faq-right d-none d-lg-block' alt="" />
        <div className="container position-relative z-2">
          <div className="row">
            <div className="col-lg-6">
              <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Common Questions</p>
              <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm mb-4'>Frequently Asked Questions</h2>

              <div className="wrapper__accordion">
                {
                  data.map((obj, i) => {
                    return <div className={"items " + (accordion == (i + 1) ? "active" : "")}>
                      <div className="head pointer" onClick={() => onClickAccordion(i + 1)}>
                        <div className="d-flex align-items-center">
                          <img src="./../images/cheveron-down.png" className='arrow flex-shrink-0 object-fit-content' alt="" />
                          <div className='semi-bold font__size--18 text__18-1024 ml-3'>{obj.title}</div>
                        </div>
                      </div>
                      <div className="body">
                        <p className='mb-0 normal font__size--18 text__18-1024 color__gray-1'>{obj.desc}</p>
                      </div>
                    </div>
                  })
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq