
import React from 'react'

const PersonalTeam = () => {
    const personil = [
        {
            img: "./../images/Image-5.png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/Image-4.png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/Image-3.png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/Image-2.png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/Image-1.png",
            name: "Samuel",
            job: "Mobile Developer"
        },
        {
            img: "./../images/Image.png",
            name: "Samuel",
            job: "Mobile Developer"
        },
    ]
    return (
        <section>
            <div className="container">
                <div className="mb-5">
                    <p className='medium font__size--18 text__18-1024 color__pink text-capitalize'>Meet the team</p>
                    <h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm'>Professional Creative Team</h2>
                </div>
            </div>
            <div className="container none-container-1024">
                <div className="wrapper__scroll-offsite">
                    <div className="wrapper__personal-grup">
                        {
                            personil.map((obj, i) => {
                                return <div className="items">
                                    <div className={"position-relative " + ((i + 1) % 2 == 0 ? "rg" : "")}>
                                        <img src={obj.img} className="user" alt="" />
                                        <img src="./../images/mouse 2.png" className='arrow' alt="" />
                                        <div className="info text-center">
                                            <img src="./../images/bottom-center.png" className='carret' alt="" />
                                            <div className="desc">
                                                <h5 className='emdium font__size--14 text__14-1024 mb-0'>{obj.name}</h5>
                                                <p className='normal font__size--14 text__14-1024 mb-0 color__gray-1'>{obj.job}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PersonalTeam